@import 'Styles/Medborgarskolan/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Wysiwyg.Base.module.scss';

.Wysiwyg {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $bold;
        color: $blue;
    }

    h3,h4,h5,h6 {
        margin-bottom: 5px;
    }

    a {
        color: $blue;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        @include href-external {
            &::after {
                content: '' !important;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: -3px 0 -3px 4px;
                background: url('#{$basepath}img/bg-icons/medborgarskolan/new/external--blue.svg') no-repeat center center;
                background-size: contain;
                transform: none;
            }
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            display: inline;
            padding: 0 !important;
            color: $blue;
            font-weight: $normal;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &::before {
                display: none;
            }

            &::after {
                content: '' !important;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: -3px 0 -3px 8px;
                background: url('#{$basepath}img/bg-icons/medborgarskolan/new/download--blue.svg') no-repeat center center !important;
                background-size: contain;
                transform: none;
            }
        }
    }

    :global {
        .figure {
            figcaption {
                margin-top: 5px;
                margin-bottom: 8px;
                padding-bottom: 0;
                color: $grey-70;
                border: none;
            }

            &--image-thumbnail {
                figcaption {
                    display: none;
                }
            }
        }
    }

    ol,
    ul {
        line-height: 1.3;
    }

    ul li {
        padding-left: 32px;

        &::before {
            content: '';
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/list-marker--blue.svg') no-repeat;
            top: 0;
            left: 2px;
            width: 24px;
            height: 24px;
            transform: none;
        }
    }

    ol {
        margin-left: 26px;
    }

    ol li {
        &::marker {
            font-weight: $bold;
            color: $blue;
        }
    }

    blockquote {
        padding: 7px 0 7px 20px;
        font-family: $font-heading;
        font-size: 2.2rem;
        line-height: 1.5;
        font-weight: $bold;
        color: $blue;

        @include media(xs) {
            padding: 14px 0 14px 24px;
            font-size: 2.8rem;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: $blue;
            border-radius: 21px;
        }

        &[cite] {
            &:after {
                padding-top: 9px;
                font-size: 1.4rem;
                color: $grey-90;

                @include media(xs) {
                    padding-top: 9px;
                    font-size: 1.6rem;
                }
            }
        }
    }

    &__Factbox {
        padding: 24px 16px;
        color: $blue;
        background-color: $yellow-20;
        border-radius: 8px;

        @include media(sm) {
            padding: 24px;
        }

        p {
            color: $grey-90;
        }
    }
}
