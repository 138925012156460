@import 'Styles/Dansskolanentre/includes';

/**
*
* HeroSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroSlider.Base.module';

.HeroSlider {
    $root: &;

    clip-path: url(#hero-mobile-clip-path);

    @include media(sm) {
        clip-path: url(#hero-clip-path);
    }

    &__Container {
        padding-top: 80px;
        padding-bottom: 100px;
        width: 100%;
        text-align: left;

        @include media(md) {
            min-height: 440px;
            padding-top: 120px;
            padding-bottom: 160px;
        }

        @include media(lg) {
            min-height: 570px;
        }

        #{$root}--PageHome & {
            padding-top: 80px;
            padding-bottom: 100px;

            @include media(md) {
                padding-top: 160px;
                padding-bottom: 180px;
            }
        }
    }

    &__ArrowIcon {
        width: 18px;
        height: 18px;
    }

    &__TextContainer {
        #{$root}--Single & {
            padding: 0 !important;
        }

        #{$root}--More & {
            padding: 0 30px !important;

            @include media(xl) {
                padding: 0 !important;
            }
        }
    }

    &__Label {
        text-shadow: 0 1px 2px rgba(black, .2);
    }

    &__Title {
        max-width: map-get($maxwidths, content);
        font-weight: $bold;
        text-shadow: 0 1px 20px rgba(black, .3);

        @include media(md) {
            font-size: 6.2rem;
        }
    }

    &__Text {
        margin-left: 0;
        text-align: left;
        text-shadow: 0 1px 4px rgba(black, .2);
    }

    &__Button {
    }
}
