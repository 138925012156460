@import 'Styles/Kulturama/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Wysiwyg.Base.module.scss';

.Wysiwyg {
    b {
        font-weight: $medium;
    }

    a {
        color: palette(pink);

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            border: 1px solid palette(black, 10);
            border-radius: 4px;
            transition: background .2s;

            &:hover {
                text-decoration: none;
                background: rgba(palette(black, 10), 0.3);
            }

            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/download--pink.svg');
            }
        }
    }

    :global {
        .figure {
            figcaption {
                color: #444;
                border-color: palette(black, 10);
            }
        }
    }

    ul li {
        &::before {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid palette(purple);
            border-radius: 0;
            background: transparent;
            transform: none;
        }
    }

    blockquote {
        color: palette(pink);
        font-weight: $medium;

        &::before {
            background: palette(pink);
        }

        &[cite] {
            &:after {
                color: palette(black, 60);
            }
        }
    }

    &__Factbox {
        background-color: palette(purple);
        color: palette(white);

        @include media(sm) {
            padding: 45px;
        }
    }
}
