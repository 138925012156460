@import 'Styles/Pahlmans/includes';

/**
*
* HeroSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroSlider.Base.module';

.HeroSlider {
    $root: &;

    &__ArrowIcon {
        width: 14px;
        height: 14px;

        &::before,
        &::after {
            height: 4px;
        }

        &::after {
            width: 4px;
            height: 100%;
        }
    }

    &__Container {

        #{$root}--PageHome & {
            min-height: 300px;
            padding-top: 80px;
            padding-bottom: 80px;

            @include media(md) {
                min-height: 400px;
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
    }

    &__Label {
        margin-bottom: 8px;
    }

    &__Title {
        width: 100%;
        font-size: 3rem;
        font-weight: $medium;
        hyphens: manual;
        word-break: break-word;

        @include media(xs) {
            hyphens: none;
        }

        @include media(sm) {
            font-size: 4rem;
        }

        @include media(md) {
            font-size: 4rem;
        }
    }

    &__Text {
        font-family: $font-base;
    }

    &__Button {
        #{$root} & {
            @include u-button(small, false);
        }
    }
}
