@import 'Styles/Base/includes';

.HeroSlider {
    $root: &;

    position: relative;

    &__DefaultBackground {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    // Third party override
    :global {
        .slick-track {
            display: flex;

            .slick-slide {
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;
                height: auto;
                position: relative;

                > div {
                    flex: 1 0 auto;
                    display: flex;
                    flex-direction: column;
                }

                &.slick-current {
                    z-index: 1;
                }
            }
        }
    }

    &--More {
        // Make sure to default to current slide if more than one slides
        :global {
            .slick-track {
                transform: translateX(-100%);
            }
        }
    }

    :global {
        .slick-dots {
            bottom: 15px;
            z-index: z();

            li {
                margin: 0;
                opacity: .5;
                transition: opacity .2s ease-in-out;

                button {
                    padding: 6px;

                    &::before {
                        content: '';
                        position: relative;
                        display: block;
                        width: 9px;
                        height: 9px;
                        padding: 0;
                        border-radius: 50%;
                        background: white;
                        opacity: 1;
                    }
                }

                &:hover {
                    opacity: .8;
                }

                &.slick-active {
                    opacity: 1;
                }
            }
        }
    }

    &__List {
        overflow: hidden;
    }

    &__Arrow {
        position: absolute;
        top: 0;
        width: 40px;
        height: 100%;
        padding: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        outline: 0;
        transition: opacity .2s ease-in-out;
        cursor: pointer;
        z-index: z();

        &--Next {
            right: 0;
        }

        &--Prev {
            left: 0;
        }

        @include media(sm) {
            width: 72px;
        }
    }

    &__ArrowIcon {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 12px;
        height: 12px;
        margin: auto;
        transform: rotate(45deg);
        transition: transform .2s ease-in-out;

        #{$root}__Arrow--Next & {
            transform: rotate(-135deg);
        }

        #{$root}__Arrow--Prev:hover & {
            transform: scale(1.25) rotate(45deg);
        }

        #{$root}__Arrow--Next:hover & {
            transform: scale(1.25) rotate(-135deg);
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background: white;
        }

        &::after {
            width: 2px;
            height: 100%;
        }
    }

    &__Item {
        flex: 1 0 auto;
        display: flex !important;
        flex-direction: column;
        height: auto;
        width: 100vw;
    }

    &__Slide {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: black;
            opacity: .3;
            z-index: 1;
        }

        #{$root}--PageHome & {
            &::before {
                display: none;
            }
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 1;
        transition: opacity .5s 1s ease-in-out;

        #{$root}__Slide--Video & {
            opacity: 0;
        }
    }

    &__Container {
        @include u-wrap(site);

        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        min-height: 300px;
        padding-top: 130px;
        padding-bottom: 130px;
        text-align: center;
        transform: translate3d(0,0,0); // Safari fix for video displayed above content
        z-index: 1;

        @include media(md) {
            min-height: 490px;
            padding-top: 160px;
            padding-bottom: 160px;
        }

        #{$root}--PageHome & {
            padding-top: 150px;
            padding-bottom: 160px;

            @include media(md) {
                padding-top: 160px;
                padding-bottom: 180px;
            }
        }
    }

    &__TextContainer {
        padding: 0 28px;

        @include media(sm) {
            padding: 0 52px;
        }

        @include media(md) {
            padding: 0 60px;
        }
    }

    &__Label {
        margin-bottom: 15px;
        color: white;
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 2.2;
        text-transform: uppercase;
        letter-spacing: 2px;

        span {
            display: block;

            @include media(sm) {
                display: inline-block;
            }
        }
    }

    &__Title {
        font-size: 4rem;
        line-height: 1.1;
        color: white;

        @include media(md) {
            font-size: 7rem;
        }
    }

    &__Text {
        margin: 10px auto 0;
        max-width: 740px;
        font-size: 1.8rem;
        font-weight: $medium;
        color: white;

        @include media(md) {
            margin-top: 24px;
            font-size: 2rem;
        }
    }

    &__Button {
        margin-top: 32px;
    }
}