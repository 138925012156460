@import 'Styles/Tillskararakademin/includes';

/**
*
* HeroSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroSlider.Base.module';

.HeroSlider {
    $root: &;

    &__ArrowIcon {
        width: 18px;
        height: 18px;
    }

    &__Label {
        text-shadow: 0 1px 2px rgba(black, .2);
    }

    &__Title {
        font-weight: $bold;
        text-shadow: 0 1px 8px rgba(black, .3);
    }

    &__Text {
        text-align: center;
        color: palette(white);
        text-shadow: 0 1px 2px rgba(black, .2);
    }

    &__Button {
        #{$root} & {
            @include u-button(solid large green);

            > span {
                padding: 0;
            }
        }
    }
}
