@import 'Styles/Dansskolanentre/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Wysiwyg.Base.module.scss';

.Wysiwyg {
    p {
        color: $text-color;
        font-weight: $light;
    }

    ul,
    ol {
        @include media(sm) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    a {
        color: $link-color;
        text-decoration: underline;

        &:hover {
            color: $link-hover-color;
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            padding: 0;
            color: $link-color;

            &:hover {
                color: $link-hover-color;
            }

            &::before {
                display: inline-block;
                position: relative;
                top: 3px;
                left: auto;
                width: 18px;
                height: 18px;
                margin: 0 5px 0 3px;
                background-image: url('#{$basepath}img/bg-icons/dansskolanentre/download--red.svg');
                transform: none;
            }

            &::after {
                margin-left: 5px;
            }
        }
    }

    > img {
        border-radius: 8px;

        @include media(sm) {
            border-radius: 12px;
        }
    }

    :global {
        .figure {
            img {
                border-radius: 8px;

                @include media(sm) {
                    border-radius: 12px;
                }
            }

            figcaption {
                margin-top: 8px;
                margin-bottom: 0;
                padding: 0;
                color: $grey-80;
                font-size: 1.4rem;
                font-weight: $light;
                line-height: 1.5;
                border: none;
            }

            &--image-thumbnail {
                figcaption {
                    display: none;
                }
            }
        }
    }

    ol,
    ul {
        list-style-position: outside;
        margin-left: 32px;
    }

    ul {
        li {
            position: relative;
            list-style-type: none;
            &::after {
                content: '';    
                position: absolute;
                top: 1px;
                left: -25px;
                width: 18px;
                height: 18px;
                background-image: url('#{$basepath}img/bg-icons/dansskolanentre/content-list-bullet.svg');

                @include media(sm) {
                    top: 3px;
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
        

        li::marker {
                font-size: 1.8rem;
                font-weight: $medium;
                color: $primary-color;
            }
        
    }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px !important;
        font-size: 16px;
        font-weight: $light;
        color: $text-color;

        @include media(sm) {
            font-size: 18px;
        }
    }

    ul li {
        &::before {
            display: none;
        }
    }

    blockquote {
        position: relative;
        color: $secondary-color;
        font-size: 3.2rem;
        line-height: 1.31;
        padding: 12px 0 12px 28px;
        border-left: 4px solid $secondary-color;

        &[cite]::after {
            color: $text-color;
            font-weight: $light !important;
            font-size: 1.4rem !important;
        }
    }

    &__Factbox {
        background-color: $red-5;
        color: $text-color;
        border-radius: 8px;

        @include media(sm) {
            padding: 32px;
            border-radius: 12px;
        }
    }
}
