@import 'Styles/Medborgarskolan/includes';

/**
*
* HeroSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroSlider.Base.module';

.HeroSlider {
    $root: &;

    &__Title {
        font-weight: $bold;
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        color: white;
        font-size: 1.6rem;
        font-weight: $medium;
    }

    &__Button {
        #{$root} & {
            @include u-button(primary, false);
        }
    }
}
